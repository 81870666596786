function Tag(props) {
    const { outline, children } = props;

    return (
        <div
            className={`${
                outline ? "bg-gray-200 text-emerald-700" : "bg-emerald-600 text-white"
            } border-2 border-emerald-600 w-fit px-1 rounded-md font-medium mr-1 mb-1 text-sm print:text-xs`}>
            {children}
        </div>
    );
}

export default Tag;
