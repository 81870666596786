import Icon from "./Icon";

function DetailWithIcon(props) {
    const { name, icon, link, printOnly } = props;

    return (
        <div className={`flex justify-between my-1 ${printOnly && "hidden print:flex"}`}>
            {icon}
            <p className="flex">
                {name}
                {link && (
                    <a className="print:hidden" href={link} target="_blank" rel="noreferrer">
                        <Icon
                            iconPackage="bi"
                            iconName="BiLinkExternal"
                            className="text-xs mt-1.5 ml-1 text-emerald-700"
                        />
                    </a>
                )}
            </p>
        </div>
    );
}

export default DetailWithIcon;
