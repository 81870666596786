import Tag from "./Tag";
import Icon from "./Icon";
import DetailWithIcon from "./DetailWithIcon";
import SidebarCategory from "./SidebarCategory";

function Sidebar() {
    return (
        <div className="bg-gradient-to-tl from-slate-200 to-slate-500 min-h-screen w-full xl:w-1/4 print:w-2/5">
            <div className="flex flex-col justify-center items-center mt-3">
                <div className="print:w-1/2 w-1/3 lg:w-1/5 xl:w-2/5 px-4 mx-auto">
                    <img alt="Profile" src="/images/default.jpg" className="rounded-full" />
                </div>
                <h1 className="print:text-3xl text-4xl font-medium">Mehmet Tetik</h1>
                <p className="text-2xl text-emerald-900">Software Engineer</p>
            </div>

            <div className="mx-5 my-5 xl:text-md print:text-xs">
                <DetailWithIcon
                    name="Amsterdam, The Netherlands"
                    icon={<Icon iconPackage="fa" iconName="FaMapMarkerAlt" className="text-xl" />}
                />
                <DetailWithIcon
                    name="mehmet1998tetik@hotmail.com"
                    icon={<Icon iconPackage="fa" iconName="FaEnvelope" className="text-xl" />}
                />
                <DetailWithIcon
                    name="+31(6) xxx xxx xx"
                    icon={<Icon iconPackage="fa" iconName="FaPhone" className="text-xl" />}
                />
                <DetailWithIcon
                    name="mehmettetik.nl"
                    icon={<Icon iconPackage="fa" iconName="FaGlobe" className="text-xl" />}
                    link="https://mehmettetik.nl"
                />
                <DetailWithIcon
                    name="linkedin.com/in/mehmet-tetik"
                    icon={<Icon iconPackage="fa" iconName="FaLinkedin" className="text-xl" />}
                    link="https://www.linkedin.com/in/mehmet-tetik/"
                />
                <DetailWithIcon
                    name="github.com/mtetik98"
                    icon={<Icon iconPackage="fa" iconName="FaGithub" className="text-xl" />}
                    link="https://github.com/mtetik98"
                />
                <DetailWithIcon
                    name="npmjs.com/~mtetik"
                    icon={<Icon iconPackage="fa" iconName="FaNpm" className="text-xl" />}
                    link="https://www.npmjs.com/~mtetik"
                />
            </div>

            <SidebarCategory
                icon={<Icon iconPackage="gi" iconName="GiMedal" className="text-2xl" />}
                title="Expertise"
                className="flex flex-wrap mx-5 my-1 xl:text-md print:text-xs mt-2">
                <Tag>Web Development</Tag>
                <Tag>Data Science</Tag>
                <Tag>Automation</Tag>
                <Tag>Scrum & Agile Methodologies</Tag>
                <Tag>Proficient Troubleshooter</Tag>
            </SidebarCategory>
            <SidebarCategory
                icon={<Icon iconPackage="fa" iconName="FaStar" className="text-2xl" />}
                title="Skills"
                className="flex flex-wrap mx-5 my-1 xl:text-md print:text-xs mt-2">
                <Tag>Collegial</Tag>
                <Tag>Result-oriented</Tag>
                <Tag>Customer oriented</Tag>
                <Tag>Creative</Tag>
                <Tag>Teamworker</Tag>
                <Tag>Versatile</Tag>
            </SidebarCategory>
            <SidebarCategory
                icon={<Icon iconPackage="fa" iconName="FaGlobeEurope" className="text-2xl" />}
                title="Languages"
                className="flex flex-wrap mx-5 my-1 xl:text-md print:text-xs mt-2">
                <Tag>Dutch</Tag>
                <Tag>English</Tag>
                <Tag>Turkish</Tag>
            </SidebarCategory>
            {/* <SidebarCategory
                icon={<Icon iconPackage="fa" iconName="FaBicycle" className="text-2xl" />}
                title="Hobbies"
                className="flex flex-wrap mx-5 my-1 xl:text-md print:text-xs mt-2">
                <Tag>Programming</Tag>
                <Tag>Gaming</Tag>
                <Tag>Weight Training</Tag>
                <Tag>Swimming</Tag>
            </SidebarCategory> */}
        </div>
    );
}

export default Sidebar;
