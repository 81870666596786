function SidebarCategory(props) {
    const { icon, title, className, children } = props;

    return (
        <>
            <div className="border-b-2 border-black mx-5 pt-3 flex">
                {icon}
                <span className="text-lg font-medium mx-3">{title}</span>
            </div>
            <div className={className}>{children}</div>
        </>
    );
}

export default SidebarCategory;
