import Tag from "./components/Tag";
import Icon from "./components/Icon";
import Sidebar from "./components/Sidebar";
import Details from "./components/Details";
import MainContent from "./components/MainContent";
import MainContentCategory from "./components/MainContentCategory";

function App() {
    return (
        <div className="overflow-x-hidden">
            <div className="select-none bg-gradient-to-tl from-red-200 to-slate-300 flex flex-col xl:flex-row print:flex-row">
                <Sidebar />
                <MainContent
                    outerClassName="bg-gray-100 w-full xl:w-5/12 print:w-2/5 min-h-screen"
                    innerClassName="mx-2 mt-3">
                    <MainContentCategory
                        icon={
                            <Icon
                                iconPackage="hi"
                                iconName="HiOutlineBriefcase"
                                className="text-4xl print:text-3xl"
                            />
                        }
                        title="Work Experience">
                        <Details
                            title="Software Engineer"
                            description="Udoc Publishing Systems B.V."
                            features={[
                                "Worked on internal projects to automate software in order to boost productivity using NextJS/ReactJS.",
                                "Worked on Cloud solutions to automate processes internally using Go and AWS (Lambda, S3, SQS, DynamoDB, etc.)",
                                "Worked on internal projects to visualize Shopify data using RemixJS & NextJS.",
                            ]}
                            subtitle="Zoetermeer, The Netherlands"
                            date="June 2022 - Present"
                        />
                    </MainContentCategory>
                    <MainContentCategory
                        icon={
                            <Icon
                                iconPackage="hi"
                                iconName="HiOutlineAcademicCap"
                                className="text-4xl print:text-3xl"
                            />
                        }
                        title="Educations">
                        <Details
                            title="Bachelor of Science (Cum Laude)"
                            description="Software Engineering"
                            details={[
                                "Minor: Applied Artificial Intelligence (8,0)",
                                "Thesis: Microservices Architecture in NodeJS (9,5)",
                            ]}
                            subtitle="University of Applied Sciences, Amsterdam"
                            date="August 2018 - June 2022"
                        />
                        <Details
                            title="Post-secondary Vocational Education"
                            description="Software Development"
                            subtitle="ROCvA, Amsterdam"
                            date="August 2015 - July 2018"
                        />
                        <Details
                            title="Pre-vocational Secondary Education"
                            description="VMBO-T"
                            details={["Research Project: Black holes & Relativity"]}
                            subtitle="Sweelinck College, Amsterdam"
                            date="August 2011 - July 2015"
                        />
                    </MainContentCategory>
                    <MainContentCategory
                        icon={
                            <Icon
                                iconPackage="ai"
                                iconName="AiOutlineFundProjectionScreen"
                                className="text-4xl print:text-3xl"
                            />
                        }
                        title="Projects">
                        <Details
                            title="NPM Package"
                            description="Jupyter Notebook Viewer"
                            details={[
                                "Developed a Jupyter notebook viewer as NPM package to be used in a React-based web application.",
                            ]}
                            date="May 2022"
                            link="https://www.npmjs.com/package/react-jupyter-notebook-viewer"
                        />
                    </MainContentCategory>
                </MainContent>

                <MainContent
                    outerClassName="bg-gray-200 w-full xl:w-5/12 print:w-2/5 min-h-screen"
                    innerClassName="mx-2 mt-3">
                    <MainContentCategory
                        icon={
                            <Icon
                                iconPackage="bs"
                                iconName="BsBuilding"
                                className="text-4xl print:text-3xl"
                            />
                        }
                        title="Internships">
                        <Details
                            title="Back-end Developer"
                            description="Udoc Publishing Systems B.V."
                            features={[
                                "Researched how to boost the productivity of the development team using Agile Scrum together with Microservices in NodeJS.",
                            ]}
                            subtitle="Zoetermeer, The Netherlands"
                            date="February 2022 - June 2022"
                        />
                        <Details
                            title="Front-end Developer"
                            description="Maxem Energy Solutions B.V."
                            features={[
                                "Researched how to make the logging system more efficient using NodeJS.",
                                "Worked on the front-end application using ReactJS.",
                            ]}
                            subtitle="Amsterdam, The Netherlands"
                            date="February 2020 - July 2020"
                        />
                        <Details
                            title="Full-stack Developer"
                            description="Onliny B.V."
                            features={[
                                "Worked on a fresh new project using Laravel, jQuery/AJAX and HTML/CSS.",
                                "Worked on Wordpress websites using HTML/CSS.",
                            ]}
                            subtitle="Amsterdam, The Netherlands"
                            date="January 2018 - June 2018"
                        />
                        <Details
                            title="Front-end Developer"
                            description="Copavo B.V."
                            features={[
                                "Worked on front-end applications using HTML/CSS and JavaScript",
                            ]}
                            subtitle="Amsterdam, The Netherlands"
                            date="August 2016 - January 2017"
                        />
                    </MainContentCategory>

                    <MainContentCategory
                        icon={
                            <Icon
                                iconPackage="hi"
                                iconName="HiOutlineTerminal"
                                className="text-4xl print:text-3xl"
                            />
                        }
                        title="Technical Skills">
                        <div className="flex flex-wrap">
                            <Tag outline>JavaScript</Tag>
                            <Tag outline>NodeJS</Tag>
                            <Tag outline>HTML/CSS</Tag>
                            <Tag outline>Docker</Tag>
                            <Tag outline>MySQL</Tag>
                            <Tag outline>NoSQL</Tag>
                            <Tag outline>NGinx</Tag>
                            <Tag outline>PHP</Tag>
                            <Tag outline>Laravel</Tag>
                            <Tag outline>Java</Tag>
                            <Tag outline>GoLang</Tag>
                            <Tag outline>ReactJS</Tag>
                            <Tag outline>TypeScript</Tag>
                            <Tag outline>Python</Tag>
                            <Tag outline>Git</Tag>
                            <Tag outline>Linux</Tag>
                            <Tag outline>AWS</Tag>
                            <Tag outline>RemixJS</Tag>
                            <Tag outline>GitHub Actions</Tag>
                        </div>
                    </MainContentCategory>
                </MainContent>
                <button
                    onClick={() => window.print()}
                    className="print:hidden hidden xl:block absolute bottom-0 right-0 m-3 xl:m-12 p-0 w-16 h-16 bg-emerald-600 rounded-full hover:bg-emerald-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none text-white">
                    <Icon iconPackage="fa" iconName="FaPrint" className="text-3xl mx-auto" />
                </button>
            </div>
        </div>
    );
}

export default App;
