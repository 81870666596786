import Icon from "./Icon";

function Details(props) {
    const { title, description, details, subtitle, features, date, link } = props;

    const renderDetails = () =>
        details.map((detail, index) => (
            <h4 key={index} className="italic">
                {detail}
            </h4>
        ));

    const renderFeatures = () =>
        features.map((feature, index) => (
            <li key={index}>
                <h4 className="italic">{feature}</h4>
            </li>
        ));

    const renderMobileDate = () => {
        const dates = date.split(" - ");

        const transformedDates = dates.map((date) => {
            if (date === "Present") return "Present";

            const dateParts = date.split(" ");
            const month = dateParts[0];
            const year = dateParts[1];

            const dateObject = new Date(year, getMonthFromString(month));
            return dateObject
                .toLocaleDateString("nl-NL", {
                    month: "2-digit",
                    year: "numeric",
                })
                .replace("-", "/");
        });

        return transformedDates.join(" - ");
    };

    const getMonthFromString = (monthString) => {
        return new Date(Date.parse(monthString + " 1, 2020")).getMonth();
    };

    return (
        <div className="flex flex-row justify-between border-emerald-600 border-l-4 pl-4 border-dotted mb-4">
            <div className="w-full">
                <h3 className="font-bold flex">
                    {title}
                    {link && (
                        <a className="print:hidden" href={link} target="_blank" rel="noreferrer">
                            <Icon
                                iconPackage="bi"
                                iconName="BiLinkExternal"
                                className="text-md mt-1.5 ml-2 text-emerald-700"
                            />
                        </a>
                    )}
                </h3>
                <h4 className="italic font-semibold">{description}</h4>
                {details && details.length && renderDetails()}
                <h5 className="font-thin">{subtitle}</h5>
                <h5 className="hidden print:flex">{date}</h5>
                {features && features.length && (
                    <ul className="list-disc ml-5 text-sm">{renderFeatures()}</ul>
                )}
            </div>
            <h5 className="print:hidden w-full text-right md:hidden">{renderMobileDate()}</h5>
            <h5 className="hidden w-full text-right md:inline md:print:hidden">{date}</h5>
        </div>
    );
}

export default Details;
