import {
    FaGlobeEurope,
    FaStar,
    FaGithub,
    FaNpm,
    FaLinkedin,
    FaGlobe,
    FaPhone,
    FaMapMarkerAlt,
    FaEnvelope,
    FaBicycle,
    FaPrint,
} from "react-icons/fa";
import { GiMedal } from "react-icons/gi";
import { BsBuilding } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { HiOutlineAcademicCap, HiOutlineTerminal, HiOutlineBriefcase } from "react-icons/hi";

function Icon(props) {
    const iconPackages = {
        fa: {
            FaGlobeEurope,
            FaStar,
            FaGithub,
            FaNpm,
            FaLinkedin,
            FaGlobe,
            FaPhone,
            FaMapMarkerAlt,
            FaEnvelope,
            FaBicycle,
            FaPrint,
        },
        gi: {
            GiMedal,
        },
        bi: {
            BiLinkExternal,
        },
        hi: {
            HiOutlineAcademicCap,
            HiOutlineTerminal,
            HiOutlineBriefcase,
        },
        ai: {
            AiOutlineFundProjectionScreen,
        },
        bs: {
            BsBuilding,
        },
    };

    const { iconPackage, iconName, className } = props;

    const DynamicIcon = () => {
        const IconComponent = iconPackages[iconPackage][iconName];
        return <IconComponent className={className} />;
    };

    return <DynamicIcon />;
}

export default Icon;
