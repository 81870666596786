function MainContent(props) {
    const { outerClassName, innerClassName, children } = props;

    return (
        <div className={outerClassName}>
            <div className={innerClassName}>{children}</div>
        </div>
    );
}

export default MainContent;
