function MainContentCategory(props) {
    const { title, icon, children } = props;
    return (
        <>
            <div className="flex border-emerald-600 border-b-2 bg-transparent p-1">
                {icon}
                <h1 className="text-2xl print:text-lg font-semibold mx-3 uppercase">{title}</h1>
            </div>
            <div className="flex flex-col my-3 mx-2 print:text-md">{children}</div>
        </>
    );
}

export default MainContentCategory;
